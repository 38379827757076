import './App.css';
import React, { useState } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Login from './pages/Login';
import Main from './pages/Main';
import DiscordDashboard from './pages/discord/Dashboard';
import DiscordGuild from './pages/discord/Guild';
import Download from './pages/download/Merge';
import DownloadLoad from './pages/download/Load';
import DownloadFile from './pages/download/File';
import AdminDashboard from './pages/admin/Dashboard';
// import NavBar from './components/NavBar';
import TemporaryNavBar from './components/TemporaryNavBar';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3877c1',
    },
    secondary: {
      main: '#e59b5a',
    },
  },
});

function App() {
  const location = useLocation();
  const [loggedIn, setLoggedIn] = useState(null);

  onAuthStateChanged(getAuth(), (user) => {
    if (user) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  });

  if (loggedIn !== null) {
    if (loggedIn) {
      return (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
            />
            {/* <NavBar /> */}
            <TemporaryNavBar loggedIn={loggedIn} />
            <AnimatePresence exitBeforeEnter initial={false}>
              <Routes location={location} key={location.pathname}>
                <Route path="/login" element={<Login location={location} />} />

                <Route path="/" element={<Main location={location} />} />

                <Route
                  path="/discord/dashboard"
                  element={<DiscordDashboard location={location} />}
                />
                <Route
                  path="/discord/guild"
                  element={<DiscordGuild location={location} />}
                />

                <Route
                  path="/down"
                  element={<Download location={location} />}
                />

                <Route
                  path="/download"
                  element={<DownloadLoad location={location} />}
                />
                <Route
                  path="/download/file"
                  element={<DownloadFile location={location} />}
                />

                <Route
                  path="/admin"
                  element={
                    loggedIn ? (
                      <AdminDashboard location={location} />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
              </Routes>
            </AnimatePresence>
          </ThemeProvider>
        </StyledEngineProvider>
      );
    }

    return <Login />;
  }

  return null;
}

export default App;
