import '../../App.css';
import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  LinearProgress,
  Fade,
} from '@mui/material';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

function File(props) {
  const { location } = props;
  const { linkToLoad } = location.state;
  const wsRef = useRef();
  const [resolution, setResolution] = useState('');
  const [resolutions, setResolutions] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [downloaded, setDownloaded] = useState(0);

  useEffect(() => {
    wsRef.current = new WebSocket('wss://socket.samjandris.com/video');
    wsRef.current.onopen = () => {
      wsRef.current.send(
        JSON.stringify({ event: 'getInfo', data: { link: linkToLoad } })
      );
    };

    wsRef.current.onmessage = (evt) => {
      const dt = JSON.parse(evt.data);
      const even = dt.event;
      const { data } = dt;
      if (even === 'linkInfo') {
        setLoaded(true);
        const newSet = [{ resolution: 'audio', name: 'Audio Only' }];
        data.forEach((item) => {
          if (item.endsWith('p')) {
            newSet.push({ resolution: item.replace('p', ''), name: item });
          }
        });
        newSet.reverse();
        setResolutions(newSet);
      } else if (even === 'dlinfo') {
        if (data.status === 'downloading') {
          setDownloaded((data.downloaded / data.total) * 100);
        } else if (data.status === 'finished') {
          const fileName = data.filename.slice(10);
          const link = document.createElement('a');
          link.href = `https://server.samjandris.com/video/download/${encodeURIComponent(
            fileName
          )}`;
          link.click();
        }
      }
    };
    return () => {
      wsRef.current.close(1000);
    };
  }, []);

  function start() {
    if (resolution !== 'audio') {
      wsRef.current.send(
        JSON.stringify({
          event: 'downloadVideo',
          data: { link: linkToLoad, resolution },
        })
      );
    } else {
      wsRef.current.send(
        JSON.stringify({ event: 'downloadAudio', data: { link: linkToLoad } })
      );
    }
  }

  const handleChange = (event) => {
    setResolution(event.target.value);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.15 }}
    >
      <Container maxWidth="xl">
        <br />
        <Typography variant="h2" align="center">
          Download Settings
        </Typography>
        <br />
        <br />

        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} sm={3.25} md={2.5} lg={1.75}>
            <FormControl
              variant="filled"
              disabled={!loaded}
              sx={{ minWidth: '100%' }}
            >
              <InputLabel>Resolution</InputLabel>
              <Select
                id="selector"
                value={resolution}
                label="Resolution"
                onChange={handleChange}
              >
                {resolutions.map((item) => (
                  <MenuItem key={item.resolution} value={item.resolution}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8.75} md={9.5} lg={10.25}>
            <Button
              onClick={() => start()}
              sx={{ width: '100%', height: '100%', fontSize: '160%' }}
              variant="contained"
              disabled={resolution === ''}
            >
              Download
            </Button>
          </Grid>
        </Grid>
        <br />
        <Fade in={downloaded > 0}>
          <LinearProgress variant="determinate" value={downloaded} />
        </Fade>
      </Container>
    </motion.div>
  );
}

File.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default File;
