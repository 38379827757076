import '../../App.css';
import React, { useState, useEffect } from 'react';
import {
  Container,
  // Box,
  Button,
  TextField,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  // Slide,
} from '@mui/material';
import { motion } from 'framer-motion';

function Merge() {
  const [linkText, setLink] = useState('');

  const [linkToLoad] = useState(null);
  const [websocket] = useState(
    new WebSocket('wss://socket.samjandris.com/video')
  );
  const [resolution, setResolution] = useState('');
  const [resolutions, setResolutions] = useState(null);
  const [loaded, setLoaded] = useState(false);

  // const [loadPage1, setLoadPage1] = useState(true);
  // const [loadPage2, setLoadPage2] = useState(false);

  useEffect(() => {
    // websocket.onopen = () => {
    //   websocket.send(
    //     JSON.stringify({ event: 'getInfo', data: { link: linkToLoad } })
    //   );
    // };

    websocket.onmessage = (evt) => {
      const dt = JSON.parse(evt.data);
      const even = dt.event;
      const { data } = dt;
      if (even === 'linkInfo') {
        setLoaded(true);
        const newSet = [{ resolution: 'audio', name: 'Audio Only' }];
        data.forEach((item) => {
          if (item.endsWith('p')) {
            newSet.push({ resolution: item.replace('p', ''), name: item });
          }
        });
        newSet.reverse();
        setResolutions(newSet);
      } else if (even === 'dlinfo') {
        if (data.status === 'downloading') {
          // setDownloaded((data.downloaded / data.total) * 100);
        } else if (data.status === 'finished') {
          const fileName = data.filename.slice(10);
          const link = document.createElement('a');
          link.href = `https://server.samjandris.com/video/download/${encodeURIComponent(
            fileName
          )}`;
          link.click();
        }
      }
    };
    return () => {
      websocket.close();
    };
  }, []);

  function start() {
    if (resolution !== 'audio') {
      websocket.send(
        JSON.stringify({
          event: 'downloadVideo',
          data: { link: linkToLoad, resolution },
        })
      );
    } else {
      websocket.send(
        JSON.stringify({ event: 'downloadAudio', data: { link: linkToLoad } })
      );
    }
  }

  const handleResolutionChange = (event) => {
    setResolution(event.target.value);
  };

  const handleLinkTextChange = (event) => {
    setLink(event.target.value);
  };

  return (
    // <motion.div
    //   initial={{ opacity: 0 }}
    //   animate={{ opacity: 1 }}
    //   exit={{ x: '-100%' }}
    //   transition={{ duration: 0.2 }}
    // >
    // <motion.div initial={{ x: 0 }} animate={{ x: loaded ? '-100%' : 0 }}>
    <Container maxWidth="xl">
      <motion.div
        initial={{ x: 0, y: 0 }}
        animate={{ x: loaded ? '-100%' : 0, y: 0 }}
      >
        {/* <Box sx={{ display: 'flex', width: '100%' }}> */}
        {/* <Slide direction="right" in={resolutions === null} appear={false}> */}
        {/* <Box width="50%" sx={{ ml: '4%', mr: '4%' }}> */}
        <br />
        <Typography variant="h2" align="center">
          Download
        </Typography>
        <br />
        <br />

        <TextField
          id="toDownload"
          onChange={handleLinkTextChange}
          label="Link to download"
          variant="filled"
          style={{ minWidth: '100%' }}
        />
        <br />
        <br />
        <Button
          onClick={() =>
            websocket.send(
              JSON.stringify({
                event: 'getInfo',
                data: { link: linkText },
              })
            )
          }
          style={{ width: '100%', height: '100%', fontSize: '150%' }}
          variant="contained"
        >
          Load
        </Button>
      </motion.div>
      {/* </Box> */}
      {/* </Slide> */}
      {/* </Container> */}

      {/* <Container maxWidth="xl"> */}
      {/* <Slide direction="left" in={resolutions !== null}> */}
      {/* <Box width="100%" sx={{ ml: '4%', mr: '4%' }}> */}

      <motion.div
        initial={{ x: '100%', y: 0 }}
        animate={{ x: loaded ? 0 : '100%', y: 0 }}
      >
        <br />
        <Typography variant="h2" align="center">
          Download Settings
        </Typography>
        <br />
        <br />

        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={2}>
            <FormControl
              variant="filled"
              disabled={!loaded}
              style={{ minWidth: '100%' }}
            >
              <InputLabel>Resolution</InputLabel>
              <Select
                id="selector"
                value={resolution}
                label="Resolution"
                onChange={handleResolutionChange}
              >
                {resolutions &&
                  resolutions.map((item) => (
                    <MenuItem key={item.resolution} value={item.resolution}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <Button
              onClick={() => start()}
              style={{ width: '100%', height: '100%', fontSize: '160%' }}
              variant="contained"
              disabled={resolution === ''}
            >
              Download
            </Button>
          </Grid>
        </Grid>
        <br />
        <br />
        <br />
        <LinearProgress />
        {/* </Box> */}
        {/* </Slide> */}
        {/* </Box> */}
      </motion.div>
    </Container>
    // </motion.div>
  );
}

export default Merge;
